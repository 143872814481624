.TextField :global .MuiInputBase-input {
  padding: 13px var(--module);
}

.TextField :global .MuiInputBase-inputSizeSmall {
  padding: 6px var(--module);
}

.TextField_with_helperText
  :global
  .MuiInputBase-formControl.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.helperText {
  margin: 0;
  padding: 0 var(--module);
  border-bottom-left-radius: var(--control-radius);
  border-bottom-right-radius: var(--control-radius);
}
