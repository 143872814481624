.Theme_space_default {
    /**
      Модуль - базовая размерная единица для интерфейса
     */
    --module: 7px;

    /* Отступы */
    --space-S: var(--module, 7px);
    --space-M: calc(var(--module, 7px) * 2);
    --space-L: calc(var(--module, 7px) * 3);
    --space-XL: calc(var(--module, 7px) * 4);

    --rhythm-h: var(--space-S); /* horizontal rhythm */
    --rhythm-v: var(--space-M); /* vertical rhythm */
}

@media screen and (min-width: 768px) {
  .Theme_space_default {
    --rhythm-h: var(--space-L);
  }
}
