.Theme_typo_default {
  /* Размер текста */
  --size-typo-S: 14px;
  --size-typo-M: 16px;
  --size-typo-L: 18px;
  --size-typo-XL: 23px;
  --size-typo-2XL: 28px;
  --size-typo-3XL: 33px;

  --size-typo-h1: var(--size-typo-3XL);
  --size-typo-h2: var(--size-typo-2XL);
  --size-typo-h3: var(--size-typo-XL);
  --size-typo-h4: var(--size-typo-L);
  --size-typo-body: var(--size-typo-M);

  --line-typo-h1: 42px;
  --line-typo-h2: 35px;
  --line-typo-h3: 28px;
  --line-typo-h4: 21px;
  --line-typo-body: 21px;

  font: 500 var(--size-typo-body)/var(--line-typo-body) var(--font-sans);
}

@media (max-width: 900px) {

}
