.Typography {
  --hmm--typography--h1-size: 27px;
  --hmm--typography--h1-line: 32px;
  --hmm--typography--h1-weight: 700;

  --hmm--typography--h2-size: 22px;
  --hmm--typography--h2-line: 28px;
  --hmm--typography--h2-weight: 700;

  --hmm--typography--h3-size: 20px;
  --hmm--typography--h3-line: 24px;
  --hmm--typography--h3-weight: 600;

  --hmm--typography--h4-size: 16px;
  --hmm--typography--h4-line: 21px;
  --hmm--typography--h4-weight: 600;

  --hmm--typography--body-size: 16px;
  --hmm--typography--body-line: 18px;
  --hmm--typography--body-weight: 400;

  --hmm--typography--small-size: 14px;
  --hmm--typography--small-line: 14px;
}

@media (min-width: 900px) {
  .Typography {
    --hmm--typography--h1-size: 33px;
    --hmm--typography--h1-line: 42px;
    --hmm--typography--h1-weight: 600;

    --hmm--typography--h2-size: 28px;
    --hmm--typography--h2-line: 32px;
    --hmm--typography--h2-weight: 600;

    --hmm--typography--h3-size: 23px;
    --hmm--typography--h3-line: 28px;
    --hmm--typography--h3-weight: 700;

    --hmm--typography--h4-size: 18px;
    --hmm--typography--h4-line: 24px;

    --hmm--typography--body-size: 16px;
    --hmm--typography--body-line: 24px;
  }
}
