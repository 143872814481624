.Page {
  font-family: var(--font-sans);
  font-size: var(--size-typo-M);
  color: var(--color-typo-primary);
  line-height: var(--line-typo-body);
  min-height: 100vh;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
