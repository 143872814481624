.Color {
  --hmm--color--blue: #00b2e3;
  --hmm--color--green: #55bb77;
  --hmm--color--violet: #cb90e4;
  --hmm--color--violet-30: rgba(203, 144, 228, 0.3);
  --hmm--color--red: #ff486a;
  --hmm--color--yellow: #ffcc00;
  --hmm--color--blue-links: #0076e3;
  --hmm--color--hmm-gradient: linear-gradient(
    124.19deg,
    #ffcc00 -2.18%,
    #cb90e4 71.18%
  );
  --hmm--color--gradient2: linear-gradient(
    310.54deg,
    #00b2e3 -10.77%,
    #cb90e4 84.17%
  );
  --hmm--color--linear: linear-gradient(180deg, #0076e3 0%, #00b2e3 100%);
  --hmm--color--linear2: linear-gradient(180deg, #cb90e4 0%, #0076e3 100%);
  --hmm--color--blue1: linear-gradient(180deg, #0076e3 0%, #cb90e4 100%);
  --hmm--color--green-50: rgba(85, 187, 119, 0.5);
  --hmm--color--green-10: rgba(49, 208, 170, 0.1);
  --hmm--color--green-75: rgba(85, 187, 119, 0.75);
  --hmm--color--red-75: rgba(255, 72, 106, 0.75);
  --hmm--color--red-50: rgba(255, 72, 106, 0.5);
  --hmm--color--red-25: rgba(255, 72, 106, 0.1);
  --hmm--color--rpost: #0059ac;
  --hmm--color--sdek: #05b647;
}
