.Icon {
  text-align: center;
  display: inline-block;
  position: relative;
  background: 50% no-repeat;
  width: var(--icon-size, 1em);
  height: var(--icon-size, 1em);
}
/* NOTE: Выравние иконок, возможно стоит для каждой группы своё сделать */
.Icon > svg {
  vertical-align: middle;
  position: relative;
  fill: currentColor;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
