.Page_view_auth {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 768px) {
  .Page_view_auth {
    background: #7e6b67 url(page_auth@desktop.jpg);
    align-items: center;
  }
}
