.LoginVariants {
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--rhythm-h);
  grid-row-gap: var(--rhythm-v);
}

.Variant {
  display: block;
}

.VariantMain {
  grid-column: 1 / 3;
}

.VariantButton {
  justify-content: left;
}

.VariantIcon {
  color: transparent;
  width: 20px;
}

.VariantForm {
  display: none;
  visibility: collapse;
}

.VariantHidden {
  display: none
}

.VariantCurrent .VariantButton {
  display: none;
}

.VariantCurrent .VariantForm {
  display: block;
  visibility: visible;
}
