@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansRegular/MuseoSansRegular.eot');
    src: url('./Theme_font_default.assets/MuseoSansRegular/MuseoSansRegular.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansRegular/MuseoSansRegular.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansRegular/MuseoSansRegular.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansRegular/MuseoSansRegular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansBold/MuseoSansBold.eot');
    src: url('./Theme_font_default.assets/MuseoSansBold/MuseoSansBold.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansBold/MuseoSansBold.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansBold/MuseoSansBold.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansBold/MuseoSansBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansItalic/MuseoSansItalic.eot');
    src: url('./Theme_font_default.assets/MuseoSansItalic/MuseoSansItalic.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansItalic/MuseoSansItalic.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansItalic/MuseoSansItalic.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansItalic/MuseoSansItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansLight/MuseoSansLight.eot');
    src: url('./Theme_font_default.assets/MuseoSansLight/MuseoSansLight.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansLight/MuseoSansLight.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansLight/MuseoSansLight.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansLight/MuseoSansLight.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansMedium/MuseoSansMedium.eot');
    src: url('./Theme_font_default.assets/MuseoSansMedium/MuseoSansMedium.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansMedium/MuseoSansMedium.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansMedium/MuseoSansMedium.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansMedium/MuseoSansMedium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansBlack/MuseoSansBlack.eot');
    src: url('./Theme_font_default.assets/MuseoSansBlack/MuseoSansBlack.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansBlack/MuseoSansBlack.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansBlack/MuseoSansBlack.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansBlack/MuseoSansBlack.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansBoldItalic/MuseoSansBoldItalic.eot');
    src: url('./Theme_font_default.assets/MuseoSansBoldItalic/MuseoSansBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansBoldItalic/MuseoSansBoldItalic.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansBoldItalic/MuseoSansBoldItalic.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansBoldItalic/MuseoSansBoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansMediumItalic/MuseoSansMediumItalic.eot');
    src: url('./Theme_font_default.assets/MuseoSansMediumItalic/MuseoSansMediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansMediumItalic/MuseoSansMediumItalic.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansMediumItalic/MuseoSansMediumItalic.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansMediumItalic/MuseoSansMediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansLightItalic/MuseoSansLightItalic.eot');
    src: url('./Theme_font_default.assets/MuseoSansLightItalic/MuseoSansLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansLightItalic/MuseoSansLightItalic.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansLightItalic/MuseoSansLightItalic.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansLightItalic/MuseoSansLightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    src: url('./Theme_font_default.assets/MuseoSansBlackItalic/MuseoSansBlackItalic.eot');
    src: url('./Theme_font_default.assets/MuseoSansBlackItalic/MuseoSansBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./Theme_font_default.assets/MuseoSansBlackItalic/MuseoSansBlackItalic.woff2') format('woff2'),
        url('./Theme_font_default.assets/MuseoSansBlackItalic/MuseoSansBlackItalic.woff') format('woff'),
        url('./Theme_font_default.assets/MuseoSansBlackItalic/MuseoSansBlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
    font-display: swap;
}

.Theme_font_default {
    --font-sans: MuseoSans, Roboto, Helvetica, Arial, sans-serif;
    --font-serif: Georgia, serif;
    --font-mono: monospace;
}
