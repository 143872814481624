.Message {
  font-family: var(--font-sans);
}

.Message_size_s {
  font-size: var(--size-typo-S);
  padding: var(--space-2XS);
}

.Message_size_m {
  font-size: var(--size-typo-M);
  padding: var(--space-2XS);
}

.Message_size_l {
  font-size: var(--size-typo-L);
  padding: var(--space-XS);
}

.Message_status_error {
  color: var(--color-typo-alert);
}
.Message_status_warning {
  color: var(--color-typo-warning);
}
.Message_status_success {
  color: var(--color-typo-success);
}
