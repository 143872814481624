.Theme_control_default {
  --control-radius: 6px;
  --control-border-width: 2px; /* Основная толщина контроллов */

  --control-button-size-xl: calc(var(--space-2XL) * 2);
  --control-button-size-l: var(--space-4XL);
  --control-button-size-m: var(--space-3XL);
  --control-button-size-s: var(--space-2XL);

  --control-checkbox-size-m: var(--space-M);
  --control-checkbox-size-l: var(--space-L);

  --control-radio-size-m: var(--space-M);
  --control-radio-size-l: var(--space-L);

  /* Нужно посмотреть для чего это использует whitepaper */
  --control-space-xl: var(--space-2XL);
  --control-space-m: 10px;
  --control-space-l: 15px;
  --control-space-s: var(--space-S);
  --control-space-xs: var(--space-S);

  /* Высота контролов */
  --control-size-s: var(--size-typo-S);
  --control-size-m: 39px;
  --control-size-l: 49px;
  --control-size-xl: var(--size-typo-XL);
}
