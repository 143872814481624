.AuthForm {
  margin: 0 auto;
  background: var(--color-bg-base);
  font-family: var(--font-sans);
  padding: var(--rhythm-h);
  max-width: 360px;
  width: 100%;
  position: relative;
}

@media screen and (min-width: 768px) {
  .AuthForm {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 360px;
  }
}

.AuthForm-Logo {
  margin: var(--space-XL) auto;
  text-align: center;
}

.AuthForm-Message {
  text-align: center;
}

.AuthForm-Field {
  margin: var(--space-L) 0;
}

.AuthForm-AlternateAction {
  margin-top: var(--space-XL);
  text-align: center;
}

.AuthForm-Title {
  margin: var(--space-S) auto;
  text-align: center;
}

.AuthForm-Factoid_floating {
  position: absolute;
  left: 100%;
  margin-left: var(--space-M);
}

.isNew {
  margin: var(--space-S) auto;
  font-weight: 600;
}

.LoginVariants {
  margin: var(--space-M) 0 !important;
}

.AuthForm-Icon {
  display: inline-block;
  margin-right: 10px;
}

.AuthForm-Back {
  visibility: hidden;
  position: absolute;
}

.AuthForm-Back_visible {
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .AuthForm-Footer {
    padding-bottom: 100px;
  }
}
