.Link {
	color: var(--color-link);
	font-family: var(--font-sans);
	cursor: pointer;
	transition: color 0.15s ease-out;
	text-decoration: none;

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.Link_view_external {
	color: var(--color-link-external);
}

.Link_view_inherited {
	color: inherit;
}

.Link:hover {
	color: var(--color-link-hover);
}

.Link[aria-disabled=true] {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	color: var(--color-typo-secondary);
	cursor: default;
}
