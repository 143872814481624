.Theme_color_default {
    --color-theme: #0097d8;
    --color-info: var(--color-theme); /* Цвет информации */
    --color-success: #85bc0d; /* Цвет успеха */
    --color-rating: #ffda60; /* Цвет успеха */
    --color-alert: #ff3b00; /* Цвет ошибки */
    --color-warning: #ffda60; /* Цвет предупреждения */
    --color-normal: #ddd; /* Цвет нейтральный РАЗОБРАТЬСЯ*/
    --color-system: #999; /* Цвет системнный РАЗОБРАТЬСЯ*/

    --color-bg-base: #fff; /* Базовый цвет поверхностей */
    --color-bg-surface: #fff; /* Базовый цвет поверхностей */
    --color-bg-gray: #f4f4f4; /* Легкий серый */
    --color-bg-border: var(--color-bg-gray); /* Легкий серый */

    /* Цвет текста */
    --color-typo-primary: #333; /* Цвет основного текста */
    --color-typo-white: #fff; /*  Белый текст */
    --color-typo-secondary: #666; /* Цвет второстепенного текста */
    --color-typo-accessory: #ddd; /* Цвет вспомогательный текста */
    /* --color-typo-accent: $color-base-additional; Цвет акцента внимания к тексту */
    --color-typo-success: var(--color-success); /* Цвет текста успеха */
    --color-typo-warning: var(--color-warning); /* Цвет текста предупреждения */
    --color-typo-alert: var(--color-alert); /* Цвет текста ошибки */

    /* Цвета ссылок */
    --color-link: var(--color-theme); /* Цвет основной ссылки */
    --color-link-external: var(--color-theme); /* Цвет дополнительной ссылки */
    /* --color-link-minor = $color-dark-primary; Цвет второстепенной ссылки */
    --color-link-hover: var(--color-alert); /* Цвет ховера ссылки */

    /* Цвета контролов bo - border, bg - background, fg - foreground */
    --color-control-bg-default: #fff;
    --color-control-bg-default-hover: #f4f4f4;
    --color-control-bg-default-press: var(--color-bg-gray);
    --color-control-bg-default-disable: var(--color-bg-gray);
    --color-control-bg-action: var(--color-success);
    --color-control-bo-default: #c4c4c4;
    --color-control-bo-default-hover: #ddd;
    --color-control-bo-default-focus: var(--color-theme);
    --color-control-bo-default-press: var(--color-typo-accessory);
    --color-control-bo-default-disable: var(--color-typo-accessory);
    --color-control-bo-action: var(--color-success);
    --color-control-fg-default: var(--color-typo-primary);
    --color-control-fg-default-hover: var(--color-typo-primary);
    --color-control-fg-default-press: var(--color-typo-primary);
    --color-control-fg-default-disable: var(--color-typo-accessory);
    --color-control-fg-action: #fff;

    /*
    МЫСЛИ:

    Cостояние - не зависит от действий пользователя на странице {default,action,disabled,checked,warning,alert,info}
    Динамика - взаимодействие пользователя с контролом. {hover,press,focus,...}
    Категория - фон, шрифт, бордер, placeholder, тень, иконка

    --color-control-{категория}-{состояние}-{динамика}

    --color-control-typo-placeholder-disable
    --color-control-bg-disable-check
    --color-control-bg-default-affect
    */
}
